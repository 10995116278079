.navigator {
  display: flex; 
  padding: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .navigator-logo {
    display: flex;
    justify-content: start;
    align-items: center;
  
    img {
      width: 50px;
    }
  
    span {
      padding-left: 20px;

      color: #fff;
      font-size: 1.2rem;
    }
  }

  .nav-menu {
    cursor: pointer;
    
    .trace {
      width: 35px;
      height: 5px;
      background-color: #fff;
      margin-bottom: 5px;
    }
    
    &:hover {
      .trace {
        background-color: #ccc;
       }
     }
    
    }

  
  

}