@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif;
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
}

.search {
  position: fixed;
  width: 250px;
  bottom: 50px;
  left: 30%;
  text-align: center;
}
.search select {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  width: 100%;
  text-align: center;
  padding: 5px;
}
.search button {
  margin-top: 15px;
  background-color: #000;
  border: 2px solid #fff;
  cursor: pointer;
  color: #fff;
  font-size: 0.8rem;
  padding: 5px;
}/*# sourceMappingURL=App.css.map */