.navigator {
  display: flex;
  padding: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.navigator .navigator-logo {
  display: flex;
  justify-content: start;
  align-items: center;
}
.navigator .navigator-logo img {
  width: 50px;
}
.navigator .navigator-logo span {
  padding-left: 20px;
  color: #fff;
  font-size: 1.2rem;
}
.navigator .nav-menu {
  cursor: pointer;
}
.navigator .nav-menu .trace {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin-bottom: 5px;
}
.navigator .nav-menu:hover .trace {
  background-color: #ccc;
}/*# sourceMappingURL=Navigator.css.map */