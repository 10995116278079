.hero-background {
  background-color: #00000082;
  width: 75vw;
  height: 100vh;


}

.hero-content {
  display: flex;
  flex-direction: row;

  height: 70%;

  justify-content: space-between;
  align-items: center;

  .champion-content {
    text-align: center;

    .champion-title {
      font-size: 4rem;
      color: #fff;
      font-weight: 900;
    }
    .champion-phrase {
      font-size: 2.5rem;
      color: #fff;
      font-weight: 600;
    }
  }
}

.screen-selector-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  margin-left: 20px;

  border: 1px solid #fff;
  cursor: pointer;

  span {
    font-size: 12px;
    position: relative;
    left: 15px;
    top: -5px;
    width: 250px;
    color: #fff;
  }

  &:hover {
    background-color: rgb(133, 34, 34);
  }

  &.active {
    background-color: rgb(133, 34, 34);
  }
}