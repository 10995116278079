.aside {
  background-color: #000000;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 25vw;
  height: 100vh;
  padding: 30px;
}
.aside .aside-nav {
  text-align: right;
}
.aside .aside-nav ul {
  margin: 15px auto;
}
.aside .aside-nav ul li {
  color: #fff;
  font-size: 1.2rem;
  display: inline;
  padding-left: 50px;
  cursor: pointer;
}
.aside .aside-nav ul li:hover {
  color: #ccc;
}
.aside .aside-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aside .aside-section .phrase {
  display: flex;
  flex-direction: row;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}
.aside .aside-section .phrase span {
  margin: auto 10px;
}
.aside .aside-section .phrase sup {
  width: 120px;
}
.aside .aside-section .phrase sup img {
  width: 100%;
}
.aside .aside-section .phrase sup:last-child {
  rotate: 180deg;
}
.aside .aside-section .video {
  width: 80%;
  height: 250px;
  background-color: #ccc;
  margin-top: 50px;
}
.aside .aside-section .download button {
  background-color: #000;
  border: 2px solid #fff;
  cursor: pointer;
  color: #fff;
  font-size: 1.2rem;
  padding: 20px;
  margin-top: 50px;
}
.aside .aside-section .download button:hover {
  background-color: #fff;
  color: #000;
}/*# sourceMappingURL=Aside.css.map */