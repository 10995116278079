.hero-background {
  background-color: rgba(0, 0, 0, 0.5098039216);
  width: 75vw;
  height: 100vh;
}

.hero-content {
  display: flex;
  flex-direction: row;
  height: 70%;
  justify-content: space-between;
  align-items: center;
}
.hero-content .champion-content {
  text-align: center;
}
.hero-content .champion-content .champion-title {
  font-size: 4rem;
  color: #fff;
  font-weight: 900;
}
.hero-content .champion-content .champion-phrase {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 600;
}

.screen-selector-item {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}
.screen-selector-item span {
  font-size: 12px;
  position: relative;
  left: 15px;
  top: -5px;
  width: 250px;
  color: #fff;
}
.screen-selector-item:hover {
  background-color: rgb(133, 34, 34);
}
.screen-selector-item.active {
  background-color: rgb(133, 34, 34);
}/*# sourceMappingURL=Hero.css.map */