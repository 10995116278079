.aside {
  background-color: #000000;
  color: #fff;

  display: flex;
  flex-direction: column;
  width: 25vw;
  height: 100vh;

  padding: 30px;

  .aside-nav {

    ul {
      margin: 15px auto;

      li {
        color: #fff;
        font-size: 1.2rem;
  
        display: inline;
        padding-left: 50px;
        cursor: pointer;

        &:hover {
          color: #ccc;
        }
      }
    }
    
    text-align: right;
  }

  .aside-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .phrase {
      display: flex;
      flex-direction: row;

      text-align: center;
      font-size: 1rem;
      font-weight: 600;

      span {
        margin: auto 10px;
      }

      sup{
        width: 20px;

        img {
          width: 100%;
        }
        &:last-child {
          rotate: 180deg;
        }
      }
    }

    .video {
      width: 80%;
      height: 250px;
      background-color: #ccc;
      margin-top: 50px;
    }

    .download {
      button {
        background-color: #000;
        border: 2px solid #fff;
        cursor: pointer;

        color: #fff;
        font-size: 1.2rem;
        padding: 20px;

        margin-top: 50px;

        &:hover {
           background-color: #fff;
           color: #000;
        }
      }
    }
  }
}

